import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { DeviceListComponent } from "./setting/device/list/device-list.component";
import { PointCardComponent } from "./setting/point/point.component";
import { SurveyRoundComponent } from "./survey-round/survey-round.component";
import { AgencyComponent } from "./setting/agency/agency.component";
import { AuthGuard } from "./_helpers/auth.guard";

const routes: Routes = [
  {
    path: "",
    component: DashboardComponent,
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: "setting/agency",
    component: AgencyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "setting/device",
    component: DeviceListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "setting/point",
    component: PointCardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "survey-round",
    component: SurveyRoundComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
