import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, timeout } from 'rxjs/operators';
import { Constant } from '../constant';

var jsonResponse: any;
@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  httpOptions = {
    headers:new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(private http:HttpClient , private constant : Constant) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    this.httpOptions.headers = headers;
   }
 
  
  // Get device list
  get(): Observable<any> {
    return this.http.get<any>(
      `${this.constant.API_DOMAIN}/api/v1/device`, 
      this.httpOptions
      );
  }

  // Get device by id
  getById(id: Number): Observable<any> {
    return this.http.get<any>(
      `${this.constant.API_DOMAIN}/api/v1/device/` + id,
       this.httpOptions
      );
  }

  // Get device by agency
  getByAgencyId(agencyId: Number): Observable<any> {
    return this.http.get<any>(
      `${this.constant.API_DOMAIN}/api/v1/device-by-agency/` + agencyId,
       this.httpOptions
      );
  }

  // Create device
  create(request): Observable<any> {
    return this.http.post<any>(
      `${this.constant.API_DOMAIN}/api/v1/device`, 
      request,this.httpOptions
    ).pipe(retry(1), catchError(this.handleError));
  }

  // Update device
  update(request,id: Number): Observable<any> {
    return this.http.put<any>(
      `${this.constant.API_DOMAIN}/api/v1/device/` + id,
       request, 
       this.httpOptions
    ).pipe(retry(1), catchError(this.handleError));
  }

  // Delete Device
  delete(id: Number): Observable<any> {
    return this.http.patch<any>(`${this.constant.API_DOMAIN}/api/v1/device/` + id,
     this.httpOptions
     );
  }

  // Delete All Device
  deleteAll(request): Observable<any> {
    return this.http.patch<any>(
      `${this.constant.API_DOMAIN}/api/v1/device-delete`, 
      JSON.stringify(request), 
      this.httpOptions
    );
  }

  // Validte Duplicate Imei
  validateDuplicateImei(request): Observable<any> {
    return this.http.post<any>(
      `${this.constant.API_DOMAIN}/api/v1/device/validate/emei`, 
      request,this.httpOptions
    ).pipe(retry(1), catchError(this.handleError));
  }

  // Get device list
  getSummaryInfo(): Observable<any> {
    return this.http.get<any>(
      `${this.constant.API_DOMAIN}/api/v1/device-summary-info`, 
      this.httpOptions
      );
  }

  createDeviceReport(request): Observable<any> {
    return this.http.post<any>(
      `${this.constant.API_DOMAIN}/api/v1/device/create-device-report`, 
      request,
      this.httpOptions
      );
  }

  // Error handling
  handleError(error) {
  let errorMessage = '';
  if(error.error instanceof ErrorEvent) { 
  // Get client-side error
  errorMessage = error.error.message;
  } else {
  // Get server-side error
  errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  }
  //window.alert(errorMessage);
  return throwError(error);
  }
}