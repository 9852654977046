import { Component, OnInit } from '@angular/core';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService, AgencyService, PointService } from 'src/app/shared';
import { TagInputModule } from 'ngx-chips';
import * as QRCode from 'qrcode';
@Component({
  selector: 'app-point',
  templateUrl: './point.component.html',
  styleUrls: ['./point.component.css']
})
export class PointCardComponent implements OnInit {

  agencyList?: Array<any> = [];
  pointList?: Array<any> = [];
  addForm :FormGroup;
  editForm :FormGroup;
  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private agencyService: AgencyService,
    private pointService: PointService,
    private commonService: CommonService
  ) {
    TagInputModule.withDefaults({
      tagInput: {
          placeholder: 'Enter a new condition',
          // add here other default values for tag-input
      },
      dropdown: {
          displayBy: 'my-display-value',
          // add here other default values for tag-input-dropdown
      }
    });

    this.addForm = fb.group({
      'point_name': ['', Validators.required],
      'agency_id': ['', Validators.required],
      'start_code': ['', Validators.required],
      'end_code': [''],
      'per_fix': [''],
    });

    this.editForm = fb.group({
      'id': ['', Validators.required],
      'name': ['', Validators.required],
      'code': ['', Validators.required],
      'agency_id': ['', Validators.required]
    });

  }

  ngOnInit() {
    this.getAgency();
    this.search();
  }

  pointIds = []
  exportAllPdf(){
    this.spinner.show();
    this.pointIds = []
    this.pointList.forEach(data => {
      this.pointIds.push(data.id);
    });
    let customerId = localStorage.getItem('customer_id');
    let formData = {
      'ids': this.pointIds,
      'customerId': customerId
    }
    this.pointService.createPointReport(formData).subscribe(res=>{
      console.log(res);
      setTimeout(() => { this.spinner.hide();}, 100);
      window.open(res.url, '_blank');
    }, error => {
      this.spinner.hide();
      console.log(error);
      if(error == "TimeoutError"){
        this.failDialog("ทำรายการเกิดเวลาที่กำหนดไว้ กรุณาทำรายการใหม่");
      }else{
        this.failDialog(error);
      }
    });
  }

  
  isValid = false
  exportVisitorPdf(){
    this.isValid = false;
    this.pointIds = []
    this.pointList.forEach(data => {
      if(data['checked']){
        this.pointIds.push(data.id);
        this.isValid = true;
      }
    });
    if(!this.isValid){
      this.warningDialog('กรุณาเลือกรายการ');
      return;
    }
    this.spinner.show();
    let customerId = localStorage.getItem('customer_id');
    let formData = {
      'ids': this.pointIds,
      'customerId': customerId
    }
    console.log(formData);
    this.pointService.createPointReport(formData).subscribe(res=>{
      console.log(res);
      setTimeout(() => {this.spinner.hide();}, 100);
      window.open(res.url, '_blank');
    }, error => {
      this.spinner.hide();
      console.log(error);
      if(error == "TimeoutError"){
        this.failDialog("ทำรายการเกิดเวลาที่กำหนดไว้ กรุณาทำรายการใหม่");
      }else{
        this.failDialog(error);
      }
    });
  }

  getAgency(){
    this.agencyService.get().subscribe(res=>{
      this.agencyList = res;
    });
  }

  selectItem(item){
    item.checked = !item.checked;
  }

  isSelectAll = false;
  selectAll(){
    this.isSelectAll = !this.isSelectAll;
    this.agencyList.forEach(element => {
      element['checked'] = this.isSelectAll;
    });
  }

  search(){
    this.spinner.show();
    this.pointList = []
    $('#point_table').DataTable().clear().destroy();
    this.pointService.get().subscribe(data => {
        this.pointList = data;
        console.log(this.pointList)
        this.pointList.forEach(element => {
          element['checked'] = false;
          QRCode.toDataURL(element['code'], { errorCorrectionLevel: 'M' })
            .then(url => {
              element['qrCodeUrl'] = url;
            })
            .catch(err => {
              console.error(err);
            });
        });
        setTimeout(()=>{
          $('#point_table').DataTable({});
        },500);
        this.spinner.hide();
    }, error => {
      this.failDialog('');
      this.spinner.hide();
      console.error(error);
    });
  }

  submitted_add = false;
  openModelCreate()
  {
    this.submitted_add = false;
    this.addForm.patchValue({
      point_name: '',
      agency_id: '',
      start_code: '',
      end_code: '',
      per_fix: '',
    });
    $('#modal-create-point').modal('show');
  }

  craete(){
    this.submitted_add = true;
    console.log(this.addForm.value);
    if(this.addForm.invalid){
      return;
    }
    this.spinner.show();
    this.pointService.create(this.addForm.value).subscribe(resp => {
      console.log(resp)
      $('#modal-create-point').modal('hide');
      this.spinner.hide();
      this.search()
    }, err => {
      this.spinner.hide();
      this.failDialog('')
    });
  }

  submitted_edit = false;
  openDialogEdit(data){
    this.submitted_edit = false;
    this.editForm.patchValue({
      id: data.id,
      name: data.name,
      code: data.code,
      agency_id: data.agency_id,
    });
    $('#modal-edit-point').modal('show');
  }

  update(){
    this.submitted_edit = true;
    console.log(this.editForm.value);
    if(this.editForm.invalid){
      return;
    }
    this.spinner.show();
    this.pointService.update(this.editForm.value,this.editForm.value.id).subscribe(resp => {
      console.log(resp)
      $('#modal-edit-point').modal('hide');
      this.spinner.hide();
      this.search()
    }, err => {
      this.spinner.hide();
      this.failDialog('')
    });
  }

  openDialogDelete(id){
    Swal.fire({
      title: 'คุณต้องการลบข้อมูลนี้ใช่ไหม',
      text: "กดยืนยันเพื่อลบข้อมูลนี้",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      console.log(result)
      if (result.value) {
        this.spinner.show()
        this.pointService.delete(id).subscribe(resp => {
          this.spinner.hide()
          this.search()
        }, err => {
          this.spinner.hide()
          this.failDialog('')
          console.log("=== err ===")
          console.log(err)
        });

      }
    })
  }

  deleteAll(){
    Swal.fire({
      title: 'คุณต้องการลบข้อมูลนี้ใช่ไหม',
      text: "กดยืนยันเพื่อลบข้อมูลนี้",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      console.log(result)
      if (result.value) {
        this.spinner.show();
        this.pointIds = []
        this.pointList.forEach(data => {
          this.pointIds.push(data.id);
        });
        this.pointService.deleteAll(this.pointIds).subscribe(resp => {
          this.spinner.hide()
          this.search()
        }, err => {
          this.spinner.hide()
          this.failDialog('')
          console.log("=== err ===")
          console.log(err)
        });

      }
    })
  }

  successDialog() {
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
  }

  failDialog(msg) {
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }

  warningDialog(msg){
    Swal.fire({
      type: 'warning',
      title: '',
      text: msg
    })
  }

}
