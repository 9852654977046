import { Component, OnInit } from '@angular/core';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AgencyService, CommonService } from 'src/app/shared';
import { Device, DeviceSummaryInfo } from 'src/app/shared/model/model';
import { DeviceService } from 'src/app/shared/service/device.service';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.css']
})
export class DeviceListComponent implements OnInit {

  searchForm: FormGroup;
  addForm: FormGroup;
  editForm: FormGroup;
  submitted_add = false;
  submitted_edit = false;
  submitted_search = false;

  //Device Model
  deviceId: Number;
  agencyList?: Array<any> = [];
  deviceList?: Array<any> = []; 
  isDuplicateImei: Boolean = false;
  deviceSummaryInfo: DeviceSummaryInfo = {limit:0, active:0, inactive:0};
  isSelectAll: Boolean = false;

  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private deviceService: DeviceService,
    private agencyService: AgencyService
  ) {
    this.searchForm = fb.group({
       
    });
    this.addForm = fb.group({
      'agency_id': ['', Validators.required],
      'name': ['', Validators.required],
      'imei': ['', Validators.required],
      'description': [''],
      'is_active': [true]
    });
    this.editForm = fb.group({
      'id': ['', Validators.required],
      'agency_id': ['', Validators.required],
      'name': ['', Validators.required],
      'imei': ['', Validators.required],
      'description': [''],
      'is_active': [true]
    });
  }

  ngOnInit() {
     //Initial
     this.getDeviceSummaryInfo();
     this.getAgency();
     this.search();
  }

  getAgency(){
    this.agencyService.get().subscribe(res=>{
      this.agencyList = this.commonService.mapDropDown_V2(res, 'id', 'name');
    });
  }

  getDeviceSummaryInfo(){
    this.deviceService.getSummaryInfo().subscribe(res=>{
        console.log(res);
        if(this.commonService.isResHasData_V2(res)){
          this.deviceSummaryInfo = res.data
        }
    });
  }

  search(){
    this.spinner.show();
    $("#device_table").DataTable().clear().destroy();
    this.deviceService.get().subscribe(res=>{
      //console.log(res);
      this.deviceList = res;
      this.deviceList.forEach(item => item.checked = false);
      setTimeout(() => {
        this.spinner.hide();
        $('#device_table').DataTable({
        });
      }, 100);
    }); 
  }

  saveDevice(value: any){
    //validate
    this.submitted_add = true;
    if(this.addForm.invalid){
      return;
    }
    this.spinner.show();
    //create request
    const request = {...value};
    if(typeof request.is_active === 'string'){
      request.is_active = request.is_active === 'false' ? false : true;
    }
    console.log(request);
    //return;
    this.deviceService.create(request).subscribe(res=>{
      //console.log(res);
      this._onSuccess('modal-device-add');
    }, error => {
      console.log(error.error); 
      if(error && error.error.code.includes('duplicate')){
        this.failDialog('ไม่สามารถทำรายการได้ เนื่องจากหมายเลข IMEI ลงทะเบียนไว้แล้ว');
      }else if(error && error.error.code.includes('limit device')){
        this.failDialog('ไม่สามารถทำรายการได้ เนื่องจากเกินจำนวนแพ็กเกจ กรุณาติดต่อผู้ดูแลระบบ');
      }else{
        this.failDialog(error);
      } 
    });
  }

  editDevice(id: Number){
    this.spinner.show();
    this.isDuplicateImei = false;
    this.deviceService.getById(id).subscribe((data: Device) =>{
      data.is_active = data.is_active === 1 ? true : false;
      this.editForm.patchValue({...data});
      $('#modal-device-edit').modal('show');
      this.spinner.hide();
    }, error => { 
      console.error(error);
      this.failDialog(error);
    });
  }

  updateDevice(value: any){
    //validate
    this.submitted_edit = true;
    if(this.editForm.invalid){
      return;
    }
    this.spinner.show();
    const request = {...value};
    if(typeof request.is_active === 'string'){
      request.is_active = request.is_active === 'false' ? false : true;
    }
    //console.log(request);
    this.deviceService.update(request, request.id).subscribe(res=>{
      console.log(res);
      this._onSuccess('modal-device-edit');
    }, error => { 
      console.log(error.error); 
      if(error && error.error.code.includes('duplicate')){
        this.failDialog('ไม่สามารถทำรายการได้ เนื่องจากหมายเลข IMEI ลงทะเบียนไว้แล้ว');
      }else if(error && error.error.code.includes('limit device')){
        this.failDialog('ไม่สามารถทำรายการได้ เนื่องจากเกินจำนวนแพ็กเกจ กรุณาติดต่อผู้ดูแลระบบ');
      }else{
        this.failDialog(error);
      }
    });
  }

  confirmDeleteDevice(id: Number){
    this.deviceId = id;
    $('#modal-delete-device').modal('show');
  }

  deleteDevice(id: Number){
    this.spinner.show();
    this.deviceService.delete(id).subscribe(res=>{
      //console.log(res);
      this._onSuccess('modal-delete-device');
    }, error => { 
      console.error(error);
      this.failDialog(error);
    });
  }

  checkDuplicateImei(isEdit: Boolean){
    const request = {...isEdit ? this.editForm.value : this.addForm.value};
    //console.log(request);
    if(request.imei){
      this.spinner.show();
      this.deviceService.validateDuplicateImei(request).subscribe(res=>{
        //console.log(res);
        this.spinner.hide();
        this.isDuplicateImei = res ? res.duplicate : false;
      }, error => { 
        console.error(error);
        this.failDialog(error);
      });
    }else{
      this.isDuplicateImei = false;
    }
  }

  selectItem(item){
    item.checked = !item.checked;
  }
   
  selectAll(){
    this.isSelectAll = !this.isSelectAll;
    this.deviceList.forEach(item => item.checked = this.isSelectAll);
  }

  confirmDeleteAllDevice(){
    $('#modal-delete-all-device').modal('show');
  }

  deleteAllDevice() {
    this.spinner.show();
    const deviceIds = this.deviceList.map(item => item.id);
    this.deviceService.deleteAll(deviceIds).subscribe(res => {
      console.log(res);
      this._onSuccess('modal-delete-all-device');
    }, err => {
      console.error(err);
      this.failDialog(err);
    });
  }

  exportPdf(type: String){
    this.spinner.show();
    const deviceIds = type === 'all' 
    ? this.deviceList.map(item => item.id) 
    : this.deviceList.filter(dev=>dev.checked).map(item => item.id)

    let customerId = localStorage.getItem('customer_id');
    const formData = {
      'ids': deviceIds,
      'customerId': customerId
    }
    console.log(formData)
    this.deviceService.createDeviceReport(formData).subscribe(res=>{
      console.log(res);
      window.open(res.data, '_blank');
      setTimeout(() => { this.spinner.hide();}, 100);
    }, error => {
      this.spinner.hide();
      console.log(error);
      if(error == "TimeoutError"){
        this.failDialog("ทำรายการเกิดเวลาที่กำหนดไว้ กรุณาทำรายการใหม่");
      }else{
        this.failDialog(error);
      }
    });
  }

  _onSuccess(modalName: String): void {
    if(modalName){
      $(`#${modalName}`).modal('hide');
    }
    this.spinner.hide();
    this.search();
    this.getDeviceSummaryInfo();
    this.successDialog();
  }

  loadDefaultScript() {
    $('#device_table').DataTable();
  }

  successDialog() {
    this.spinner.hide();
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
  }

  failDialog(msg) { 
    this.spinner.hide();
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }

}
