import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { retry, catchError, timeout } from "rxjs/operators";
import { Constant } from "../constant";

@Injectable({
  providedIn: "root",
})
export class SurveyRoundService {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(private http: HttpClient, private constant: Constant) {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json; charset=utf-8");
    this.httpOptions.headers = headers;
  }

  get(): Observable<any> {
    return this.http.get<any>(
      this.constant.API_DOMAIN + "/api/v1/survey-round",
      this.httpOptions
    );
  }

  getPointByAgencyId(agency_id:any): Observable<any>{
    return this.http.get<any>(
      this.constant.API_DOMAIN + "/api/v1/point-by-agency/"+agency_id,
      this.httpOptions
    );
  }

  getDeviceByAgencyId(agency_id:any): Observable<any>{
    return this.http.get<any>(
      this.constant.API_DOMAIN + "/api/v1/device-by-agency/"+agency_id,
      this.httpOptions
    );
  }

  create(req :any): Observable<any> {
    return this.http.post<any>(this.constant.API_DOMAIN + "/api/v1/survey-round", JSON.stringify(req), this.httpOptions);
  }

  delete(id): Observable<any> {
    return this.http.patch<any>(this.constant.API_DOMAIN + "/api/v1/survey-round/"+id, this.httpOptions);
  }

  getByid(id): Observable<any>
  {
    return this.http.get<any>(
      this.constant.API_DOMAIN + "/api/v1/survey-round/"+id,
      this.httpOptions
    );
  }

  update(req,id): Observable<any> {
    return this.http.put<any>(this.constant.API_DOMAIN + "/api/v1/survey-round/"+id, JSON.stringify(req), this.httpOptions);
  }



}
